<template>
	<div>
		<!--Title: {{title}}<br>
		Date Time: {{eventDateTime}}<br>
		Text: {{bodyText}}<br>
		Venue: {{venue}}<br>
		Tickets: {{tickets}}<br>
		Genres: {{genres}}<br>
		Booking {{bookingDetails}}<br>
		Image {{imageUrl}}-->
		<!-- Backend Errors -->
		<div v-if="isError === true">
			<div v-for="error in errors" :key="error">
				<div v-for="el in error" :key="el">
					<ion-item>
						<div tabindex="0"></div><!-- bug fix for safari 14 -->
						<ion-text color="danger">{{el}}</ion-text>
					</ion-item>
				</div>
			</div>
		</div>
		<!-- Image -->
		<ion-item v-if="imageUrl != null">
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-img :src="imageUrl" />
		</ion-item>
		<!-- Title -->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-input v-model="title" type="text" placeholder="Title"></ion-input>
		</ion-item>
		<!-- Date -->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-datetime value="2021-01-19" placeholder="Select Date" v-model='eventDateTime'></ion-datetime>
		</ion-item>
		<!-- Time -->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-datetime display-format="h:mm A" minute-values="0,15,30,45" placeholder="Select Time" v-model="eventDateTime"></ion-datetime>
		</ion-item>
		<!-- Body -->
		<div>
			<TextWrapper 
				:store="store.state"
				input-type="textarea"
				:is-mentions="true" 
				:is-hashtags="true" 
				:has-button="false" 
				:set-delimeter="null"
				@input-data="getInputData" />			
		</div>
		<!-- Venue -->
		<ion-item v-if="venue.length < 1" @click="updateRoute('/add-venue')" button detail>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			Add Venue
		</ion-item>
		<!-- Venue Set -->
		<ion-item v-else button>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-icon slot="end" color="danger" :icon="trash" @click="removeVenue()" />
			<ion-label>
				<h4>{{venue.name}}</h4>
				<p>{{venue.address}}</p>
			</ion-label>
		</ion-item>
		<!-- Tickets -->
		<ion-item @click="updateRoute('/setup-tickets')" button detail>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			Setup Tickets
		</ion-item>
		<!-- Added Tickets -->
		<ion-item v-if="tickets.length > 0">
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-list style="width:100%;">
				<ion-item v-for="(ticket,index) in tickets" :key="ticket">
					<ion-label class="text-cap">{{ticket.type.replace('-', ' ')}} <span v-if="ticket.price != null">{{ticket.price}}</span></ion-label>
					<ion-icon slot="end" color="danger" :icon="trash" @click="removeTicket(index)" />
				</ion-item>
			</ion-list>
		</ion-item>
		<!-- Tickets -->
		<ion-item @click="updateRoute('/add-genres')" button detail>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			Add Genres
		</ion-item>
		<ion-item v-if="genres.length > 0">
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-chip v-for="(genre, index) in genres" :key="genre" @click.prevent="removeGenre(index)">
				<ion-label>{{genre.name}}</ion-label>
				<ion-icon :icon="close"></ion-icon>
			</ion-chip>
		</ion-item>
		<!-- Booking Form -->
		<ion-item @click="updateRoute('/booking-details')" button detail>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			Booking Details
		</ion-item>
		<!-- Booking Details -->
		<ion-item v-if="bookingDetails != null && bookingDetails != ''">
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			{{bookingDetails}}
		</ion-item>
		<!-- Status -->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-select placeholder="Post Visibility" v-model="status">
				<ion-select-option :value="1">Published</ion-select-option>
				<ion-select-option :value="0">Unpublished Draft</ion-select-option>
			</ion-select>
		</ion-item>
		<!-- Actions -->
		<ion-item lines="none">
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-button v-if="imageUrl === null" slot="start" expand="full" size="small" color="light" @click="takePicture()">
				<ion-icon :icon="image"></ion-icon>
			</ion-button>
			<ion-button v-if="imageUrl != null" slot="start" expand="full" size="small" color="danger" @click="imageUrl = null">
				<ion-icon :icon="trash"></ion-icon>
			</ion-button>
			<ion-button slot="end" expand="full" size="small" color="primary" @click.prevent="storePost()">
				Post
			</ion-button>
		</ion-item>
	</div>
</template>

<script>
import { IonItem, IonInput, IonDatetime, IonSelect, IonSelectOption, IonIcon, IonLabel, IonChip, IonList, IonButton, loadingController, toastController, IonImg, IonText } from '@ionic/vue';
import { defineComponent, ref, computed } from 'vue';
import { image, trash, close } from 'ionicons/icons';
import { useStore } from "vuex";
import apiClient from '../services/api';
import { useRouter } from 'vue-router';
const { Camera } = Plugins;
import { Plugins, CameraSource, CameraResultType } from "@capacitor/core";
import TextWrapper from './TextWrapper.vue';

export default defineComponent( {
	components: { IonItem, IonInput, IonDatetime, IonSelect, IonSelectOption, TextWrapper, IonIcon, IonLabel, IonChip, IonList, IonButton, IonImg, IonText },
	name: 'ConcertForm',
	setup() {
		const isError = ref(false);
		const errors = ref([]);
		const router = useRouter();
		const store = useStore();
		const authToken = computed(() => store.state.authToken);
		const title = ref(null);
		const timeout = { default: 6000 }
		const toastMessage = ref(null);
		const eventDateTime = ref(null);
		const venue = computed(() => store.state.venue);
		const tickets = computed(() => store.state.tickets);
		const genres = computed(() => store.state.storedGenres);
		const bookingDetails = computed(() => store.state.bookingDetails);
		const bodyText = ref(null);
		const status = ref(1);
		const imageUrl = ref(null);

		function updateRoute(val) {
			router.push(val)
		}

		function getInputData(e) {
			if(e) {
				bodyText.value = e.text
			}
		}

		function removeVenue() {
			store.commit('setVenue', [])
		}

		function removeTicket(i) {
			store.commit('removeTicket', i)
		}

		function removeGenre(i) {
			store.commit('removeStoredGenre', i)
		}

		async function takePicture() {
			let isAvailable = true;
			if (!isAvailable) {
				// Have the user upload a file instead
				alert("No Camera Aailable");
			} else {
				// Otherwise, make the call:
				try {
					const image = await Camera.getPhoto({
						quality: 90,
						allowEditing: true,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Prompt
					});
					console.log("image", image);
					// image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
					imageUrl.value = image.dataUrl;
					// can be set to the src of an image now
					console.log(image);
				} catch (e) {
					console.log("error", e);
				}
			}
		}

		function storePost() {
			presentLoading()
			apiClient.post('/api/store/post', 
				{
					title: title.value,  
					body: bodyText.value, 
					status: status.value, 
					post_type: 'concert' ,
					image: imageUrl.value,
					category: 'concert',
					date_time: eventDateTime.value, 
					venue_id: venue.value.id,
					tickets: tickets.value,
					genres: genres.value,   
					booking_details: bookingDetails.value  
				},
				{
					headers: {
						'Authorization':  `Bearer ${authToken.value}`
					}
				}).then(response => {
					// Success
					if(response.data.message == 'success') {
						toastMessage.value = 'Your post has been created.'
						openToast()
						isError.value = false
						errors.value = []
						resetForm()
						store.commit('setFeedTrigger', true);
						router.push('home')
					}
					// Fail Validation
					if(response.data.message == 'fail-validation') {
						toastMessage.value = 'There was a problem.'
						openToast()
						isError.value = true
						errors.value = response.data.errors            
					}
					// Fail Auth
					if(response.data.message == 'fail-auth') {
						toastMessage.value = 'You must be signed in to update your profile.'
						openToast()
					}
				}).catch(error => {
					// credentials didn't match
					isError.value = true
					errors.value = error.response.data.errors
				});        

		}

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
					{
						icon: 'close',
						role: 'cancel',
						handler: () => {

						}
					}
				]
			})
			return toast.present();
		}

		function resetForm() {
			title.value = null
			bodyText.value = null
			status.value = 1
			imageUrl.value = null
			eventDateTime.value = null
			venue.value = []
			tickets.value = []
			genres.value = null
			bookingDetails.value = null
		}

		return {
			router, title, eventDateTime, venue, tickets, updateRoute, store, bodyText, getInputData, status, image, trash, close, removeVenue, removeTicket, genres, removeGenre, bookingDetails, imageUrl, takePicture, storePost, isError, errors
		}
	}
});
</script>

<style scoped>
	.text-cap {
		text-transform:capitalize!important;
	}
</style>
